/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from '../../../environments/environment';
import RenderRoutes from './components/helpers/routes';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { routes } from './routes/router';
/**
 * App main component
 * @return {component} App main component
 */
function App() {
  const {t} = useTranslation('common');
  useEffect(() => {
    document.title = environment.env ? environment.env : 'Operational System';
  });
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <React.Suspense fallback={<Loading />}>
        <RenderRoutes routes={routes} />
      </React.Suspense>
    </BrowserRouter>
  );
}

function Loading(){
  return (
    <h1>Loading...</h1>
  );
}

export default App;
