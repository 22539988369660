import React from 'react';

const RecoverAccountComponent = React.lazy(() => import('./RecoverAccount/RecoverAccount'));
export const RecoverAccountRoutes = [
  {
    path: '/recover/:user',
    component: RecoverAccountComponent,
    key: 'root',
    exact: true,
    routes: [],
  }
];
