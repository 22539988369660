/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Switch } from 'react-router-dom'; 

const RenderRoutes: React.ComponentType<any> = ({routes}) => {
  return (
    <Switch>
      {routes.map((route: any) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
};

export function RouteWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      exact
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}


export default RenderRoutes;