import React from 'react';

const SettingsComponent = React.lazy(() => import('./Configuration/Configuration'));
export const SettingsRoutes = [
  {
    path: '/settings',
    component: SettingsComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
];
