import React from 'react';

// const UsersListComponent = React.lazy(() => import('./UsersList/UsersList'));
const UsersComponent = React.lazy(() => import('./Users'));
const PractitionersNewComponent = React.lazy(() => import("../users-renowed/users"));
const CouponsTemplate = React.lazy(() => import('./UsersList/Coupons-template'));
const UserDetailComponent = React.lazy(() => import('./UserDetail/UserDetail'));
const AuthenticationUserComponent = React.lazy(() => import('../../components/AuthenticationUser/AuthenticationUser'));
export const UsersRoutes = [
  {
    path: '/user',
    component: UsersComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/coupons-template',
    component: CouponsTemplate,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/practitioner',
    component: UsersComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/practitioner-new',
    component: PractitionersNewComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/user/:user',
    component: UserDetailComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/user/authentication/:user',
    component: AuthenticationUserComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
];
