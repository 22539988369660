import React from 'react';

const AuthenticationComponent = React.lazy(() => import('./Authentication/Authentication'));
const LoginComponent = React.lazy(() => import('./Login/Login'));
const SignInCallbackComponeont = React.lazy(() => import('./Login/callback/signin-callback'));
const LoginRedirectComponent = React.lazy(() => import('./Login/login-redirect'));

export const AuthenticationRoutes = [
  {
    path: '/',
    component: LoginComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
  {
    path: '/login',
    component: LoginRedirectComponent,
    key: 'root',
    exact: true,
    routes: [],
  },{
      path: '/login/verification',
      component: AuthenticationComponent,
      key: 'root',
      exact: true,
      routes: [],
  },
  {
    path: "/signin-callback",
    component: SignInCallbackComponeont,
    key: "signin-callback",
    exact: true,
    routes: []
  }
];
