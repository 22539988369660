import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ns1 from './en/en.json';
import ns2 from './es/es.json';
import professionEn from './en/profession.json';
import professionEs from './es/profession.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(LanguageDetector)
.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  // lng: 'en', // language to use
  resources: {
    en: {
      common: ns1, // 'common' is our custom namespace
      profession: professionEn
    },
    es: {
      common: ns2,
      profession: professionEs
    }
  }
});

export default i18n;
