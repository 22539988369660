import React from 'react';

const DashboardComponent = React.lazy(() => import('./Dashboard/Dashboard'));
export const DashboardRoutes = [
  {
    path: '/home',
    component: DashboardComponent,
    key: 'root',
    exact: true,
    routes: [],
  },
];
