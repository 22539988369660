import { ENVIRONMENT_GLOBAL } from './global';

export const environment = {
  ...ENVIRONMENT_GLOBAL,
  env: 'Operational System 1.3.1',
  // Ip for send the ip hubio in the emails
  protocol: 'https://',
  hubioIp: 'op.doctorprox.com',
  gatewayIp: 'op.doctorprox.com',
  gatewayPort: '443',
  production: true,
  qa: false,
  defaultUrl: `https://op.doctorprox.com:443`,
  clientId: "swagger",
  clientSecret: "Th8E5LLop7say4HGXcLPCRJP12gEInPi"
};
